import {
  DatetimeColumnDisplayFormat,
  DisplayTableColumnId,
  DisplayTableOutputColumn,
  PivotAggregation,
  PivotFieldInputType,
  PivotGroupByField,
  PivotTableConfig,
  PivotTruncateDateInterval,
  PivotValueField,
  PushdownSqlDialect,
  pivotValueFieldName,
  sortSubsetInSameOrder,
} from "@hex/common";
import { last, pick } from "lodash";

import { DataframeColumn } from "../../../../renderer/table/data/dataframeColumns";

import { DragItem, PivotFieldLocator, PivotInputField } from "./pivotUiTypes";

export function withoutField(
  config: PivotTableConfig,
  loc: PivotFieldLocator,
): PivotTableConfig {
  const section = config[loc.section].slice();
  section.splice(loc.index, 1);
  return {
    ...config,
    [loc.section]: section,
  };
}

export const pivotTruncateLabels: Record<PivotTruncateDateInterval, string> = {
  [PivotTruncateDateInterval.HOUR]: "Hour",
  [PivotTruncateDateInterval.DAY]: "Day",
  [PivotTruncateDateInterval.WEEK]: "Week",
  [PivotTruncateDateInterval.MONTH]: "Month",
  [PivotTruncateDateInterval.QUARTER]: "Quarter",
  [PivotTruncateDateInterval.YEAR]: "Year",
} as const;

export const TRUNCATION_UI_ORDER: Array<PivotTruncateDateInterval | undefined> =
  [
    undefined,
    PivotTruncateDateInterval.HOUR,
    PivotTruncateDateInterval.DAY,
    PivotTruncateDateInterval.WEEK,
    PivotTruncateDateInterval.MONTH,
    PivotTruncateDateInterval.QUARTER,
    PivotTruncateDateInterval.YEAR,
  ];
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const TRUNCATION_UI_DATE_ORDER = TRUNCATION_UI_ORDER.filter(
  (truncation) => truncation !== "HOUR" && truncation !== "DAY",
);

// gives the preference order for truncation default value
const TRUNCATION_DEFAULT_ORDER: Array<PivotTruncateDateInterval | undefined> = [
  PivotTruncateDateInterval.DAY,
  PivotTruncateDateInterval.WEEK,
  PivotTruncateDateInterval.MONTH,
  PivotTruncateDateInterval.QUARTER,
  PivotTruncateDateInterval.YEAR,
  PivotTruncateDateInterval.HOUR,
  undefined,
];
// date field should first default to no truncation
const TRUNCATION_DEFAULT_DATE_ORDER: Array<
  PivotTruncateDateInterval | undefined
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
> = [undefined, ...TRUNCATION_DEFAULT_ORDER.slice(0, -1)];

interface GetTruncateOptionsForFieldArgs {
  field: string;
  fieldType: "DATETIME" | "DATETIMETZ" | "DATE";
  config: PivotTableConfig;
}
export function getTruncateOptionsForField({
  config,
  field,
  fieldType,
}: GetTruncateOptionsForFieldArgs): Array<
  PivotTruncateDateInterval | undefined
> {
  const existingTruncationsForField = [...config.rows, ...config.columns]
    .filter((value) => value.field === field)
    .map(({ truncateTo }) => truncateTo);
  const options =
    fieldType === "DATE" ? TRUNCATION_UI_DATE_ORDER : TRUNCATION_UI_ORDER;
  return options.filter(
    (truncation) => !existingTruncationsForField.includes(truncation),
  );
}

export const pivotAggregationLabels: Record<PivotAggregation, string> = {
  [PivotAggregation.SUM]: "Sum",
  [PivotAggregation.AVERAGE]: "Average",
  [PivotAggregation.MIN]: "Min",
  [PivotAggregation.MAX]: "Max",
  [PivotAggregation.MEDIAN]: "Median",
  [PivotAggregation.COUNT]: "Count",
  [PivotAggregation.COUNT_DISTINCT]: "Count (distinct)",
} as const;

export function getAggregationOptionsForType(
  type: PivotFieldInputType,
): PivotAggregation[] {
  switch (type) {
    case "NUMBER":
      return [
        PivotAggregation.SUM,
        PivotAggregation.AVERAGE,
        PivotAggregation.MIN,
        PivotAggregation.MAX,
        PivotAggregation.MEDIAN,
        PivotAggregation.COUNT,
        PivotAggregation.COUNT_DISTINCT,
      ];
    case "DATE":
    case "TIME":
    case "DATETIME":
    case "DATETIMETZ":
      return [
        PivotAggregation.MIN,
        PivotAggregation.MAX,
        PivotAggregation.MEDIAN,
        PivotAggregation.COUNT,
        PivotAggregation.COUNT_DISTINCT,
      ];
    case "STRING":
    case "BOOLEAN":
    case "UNKNOWN":
    default:
      return [PivotAggregation.COUNT, PivotAggregation.COUNT_DISTINCT];
  }
}

interface GetAggregationOptionsForFieldArgs {
  field: string;
  fieldType: PivotFieldInputType;
  values: PivotValueField[];
  dialect: PushdownSqlDialect;
}
interface PivotAggregationOption {
  value: PivotAggregation;
  disabled: boolean;
  tooltip?: string;
}
export function getAggregationOptionsForField({
  dialect,
  field,
  fieldType,
  values,
}: GetAggregationOptionsForFieldArgs): PivotAggregationOption[] {
  const existingAggregationsForField = values
    .filter((value) => value.field === field)
    .map(({ aggregation }) => aggregation);
  return getAggregationOptionsForType(fieldType).map((value) => {
    if (existingAggregationsForField.includes(value)) {
      return {
        value,
        disabled: true,
        tooltip: "Duplicate aggregation",
      };
    } else if (
      value === "MEDIAN" &&
      (dialect === "mysql" ||
        dialect === "cloudsql__mysql" ||
        dialect === "mariadb")
    ) {
      return {
        value,
        disabled: true,
        tooltip: "Median aggregation unsupported by MySQL/MariaDB",
      };
    } else {
      return { value, disabled: false };
    }
  });
}

interface CanAddFieldArgs {
  field: PivotInputField;
  section: "rows" | "columns" | "values";
  config: PivotTableConfig;
  dialect: PushdownSqlDialect;
}
export function canAddField({
  config,
  dialect,
  field,
  section,
}: CanAddFieldArgs): boolean {
  if (section === "values") {
    // field can be added to values if there are aggregation options left for it
    const aggregationOptions = getAggregationOptionsForField({
      dialect,
      field: field.id,
      fieldType: field.type,
      values: config.values,
    });
    return aggregationOptions.filter(({ disabled }) => !disabled).length > 0;
  } else {
    if (
      field.type === "DATETIME" ||
      field.type === "DATETIMETZ" ||
      field.type === "DATE"
    ) {
      // date field can be added if there are truncation options left
      const truncationOptions = getTruncateOptionsForField({
        field: field.id,
        fieldType: field.type,
        config,
      });
      return truncationOptions.length > 0;
    } else {
      // other fields can't have any duplicates
      return [...config.rows, ...config.columns].every(
        (value) => value.field !== field.id,
      );
    }
  }
}

interface GetDropValueForSectionArgs {
  item: DragItem;
  fieldType: PivotFieldInputType;
  section: "rows" | "columns" | "values";
  config: PivotTableConfig;
  dialect: PushdownSqlDialect;
}
export function getDropValueForSection({
  config,
  dialect,
  fieldType,
  item,
  section,
}: GetDropValueForSectionArgs): PivotGroupByField | PivotValueField {
  switch (section) {
    case "rows":
    case "columns": {
      if (
        fieldType === "DATETIME" ||
        fieldType === "DATETIMETZ" ||
        fieldType === "DATE"
      ) {
        const truncationOptions = getTruncateOptionsForField({
          field: item.field,
          fieldType,
          config,
        });
        const [defaultTruncateTo] = sortSubsetInSameOrder(
          truncationOptions,
          fieldType === "DATE"
            ? TRUNCATION_DEFAULT_DATE_ORDER
            : TRUNCATION_DEFAULT_ORDER,
        );
        return {
          fieldType,
          truncateTo: defaultTruncateTo,
          displayFormat: DatetimeColumnDisplayFormat.guard(item.displayFormat)
            ? item.displayFormat
            : undefined,
          ...pick(item, "field", "nameOverride", "truncateTo"),
        } as PivotGroupByField;
      } else {
        return {
          fieldType,
          field: item.field,
        };
      }
    }
    case "values": {
      // TODO(EXPLORE): EXP-1210 Don't include an aggregation for measures - we need to
      // update `PivotValueField` so that it supports measures.
      const aggregationOptions = getAggregationOptionsForField({
        field: item.field,
        fieldType,
        values: config.values,
        dialect,
      });
      const aggregation = aggregationOptions.find(({ disabled }) => !disabled);
      return {
        fieldType,
        aggregation: aggregation?.value,
        ...pick(item, "field", "nameOverride", "aggregation", "displayFormat"),
      } as PivotValueField;
    }
  }
}

export function getValueOutputType(
  fieldType: PivotFieldInputType,
  aggregation: PivotAggregation,
): "DATETIME" | "DATE" | "TIME" | "NUMBER" {
  switch (fieldType) {
    case "DATE":
    case "TIME":
    case "DATETIME":
    case "DATETIMETZ":
      return aggregation === "COUNT" || aggregation === "COUNT_DISTINCT"
        ? "NUMBER"
        : "DATETIME";
    case "BOOLEAN":
    case "STRING":
    case "UNKNOWN":
    case "NUMBER":
      return "NUMBER";
  }
}

/**
 * This function is specifically for use with the ConditionalFormattingDialog.
 * For pivot tables we slightly overload conditional formatting and interpret
 * column filters to reference whole pivot value fields instead of specific
 * column ids (so they apply e.g. to all columns derived from "sales (sum)"
 * rather than a specific "sales (sum)" column)
 *
 * We derive the types of these value columns based on the actual display table
 * output rather than the config + source dataframe column types so that this
 * can be used by display tables showing a pivot table as well (which don't
 * have access to the source dataframe)
 */
export function getValueColumns(
  config: PivotTableConfig,
  columns: DisplayTableOutputColumn[],
): readonly DataframeColumn[] {
  return config.values.map(pivotValueFieldName).map((columnName) => {
    const columnType =
      columns.find((column) => last(column.columnName) === columnName)
        ?.columnType ?? "UNKNOWN";
    return {
      columnId: columnName as DisplayTableColumnId,
      columnName,
      columnType,
    };
  });
}
